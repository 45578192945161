import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { fadeIn } from 'libs/animations';
import { useHits, useInstantSearch } from 'react-instantsearch';

import MediumWidth from 'layouts/medium-width';
import Spacing from 'layouts/Spacing';
import Loading from 'components/Loading';
import Article, { Grid } from 'components/Article';

const PostListInner = styled(Grid)`
	.component__article {
		// First three posts one-third width
		&:nth-child(-n + 3) {
			${p =>
				p.theme.media.large(css`
					flex: 1 1 calc(33.333% - 40px) !important;
				`)}
			${p =>
				p.theme.media.mediumOnly(css`
					flex: 1 1 calc(50% - 40px);
				`)}
		}
		.component__article--text {
			.text p:last-of-type {
				margin-bottom: 0;
			}
			.buttons {
				margin-top: 10px;
			}
		}
	}
`;

const NoResults = styled.div`
	text-align: center;
	animation-name: ${fadeIn};
	animation-duration: 1s;
	animation-fill-mode: forwards;
`;

export default function SearchHits() {
	const { hits } = useHits();
	const { status } = useInstantSearch();
	const [loading, setLoading] = useState(true);

	const searching = status === 'loading';

	useEffect(() => {
		if (hits && !searching) setLoading(false);
		//eslint-disable-next-line
	}, [hits?.length]);

	const settings = {
		headinglevel: 'h3',
		hidetitle: 'false',
		align: 'left',
		linkelement: 'text',
		imageclick: true,
		showTags: true,
	};

	return (
		<Spacing>
			<div id="blog-archive-list" data-cy="blog-archive-list">
				{(loading && <Loading fullheight="true" />) || (
					<>
						{hits?.length > 0 && (
							<>
								{/** First post */}
								<Article
									key={hits[0]?.id || 'first'}
									{...hits[0]}
									{...settings}
									direction="horizontal"
									headinglevel="h2"
								/>

								{/** If more than 1 post */}
								{hits.length > 1 && (
									<PostListInner
										id="blog-archive-list-rest"
										itemcount={
											hits?.length % 2 === 0
												? 'even'
												: 'odd'
										}>
										{hits.map((post, i) => {
											if (i === 0) {
												return null;
											}
											return (
												<Article
													key={post?.id || i}
													{...post}
													{...settings}
												/>
											);
										})}
									</PostListInner>
								)}
							</>
						)}
						{!searching &&
							hits.length === 0 &&
							status === 'idle' && (
								<NoResults>
									<MediumWidth>
										Vi fant ingen blogginnlegg på din
										filtrering. Kanskje du kan prøve noen
										andre søkeord?
									</MediumWidth>
								</NoResults>
							)}
					</>
				)}
			</div>
		</Spacing>
	);
}
