import React from 'react';
import styled, { css } from 'styled-components';

import { formatDate } from 'libs/content';
import { AnchorLink } from 'components/Link';

const Meta = styled.aside`
	margin: 20px 0;
`;

const MetaList = styled.ul`
	list-style: none;
	padding: 0px;
	margin: 0px;
	text-align: ${p => (p.align === 'center' ? 'center' : 'left')};
`;

const TagSharedStyle = () => css`
	display: block;
	font-size: 14px;
	line-height: 18px;
	font-weight: 600;
	padding: 10px 15px;
	text-decoration: none;
	border-radius: ${p => p.theme.utils.borderRadius};
`;

const MetaListItem = styled.li`
	display: inline-block;
	margin: 5px 5px 0 0;
	&:last-of-type {
		margin-right: 0;
	}
	a {
		${TagSharedStyle}
		background-color: ${p => p.theme.colors.blue300};
		color: ${p => p.theme.colors.blue700};
		&:hover {
			color: ${p => p.theme.colors.blue900};
			background-color: ${p => p.theme.colors.blue400};
		}
	}
	span {
		display: inline-block;
		background-color: ${p => p.theme.colors.grey300};
		color: ${p => p.theme.colors.grey800};
		${TagSharedStyle}
	}
`;

export default function TagsAndDate({
	tags = [],
	date = '',
	align = 'center',
	style,
}) {
	return (
		<Meta style={style}>
			<MetaList
				id="blog-article-tags"
				className="tags"
				data-cy="blog-article-tags"
				align={align}>
				{tags?.length > 0 &&
					tags.map((tag, i) => {
						if (!tag || i >= 3) return null;

						const title =
							typeof tag === 'string' ? tag : tag?.title;

						if (!title) return null;

						return (
							<MetaListItem key={title + '-' + i || i}>
								<AnchorLink
									href={`/blogg/?${
										process.env
											.GATSBY_ALGOLIA_BLOG_INDEX_NAME ||
										'prod_NTENO_blogposts'
									}%5BrefinementList%5D%5Bcategory%5D%5B0%5D=${title}`}
									title={`Se blogginnlegg innfor kategorien ${title}`}
									rel="category">
									{tag}
								</AnchorLink>
							</MetaListItem>
						);
					})}

				{date && (
					<MetaListItem key={date}>
						<span>{formatDate(date)}</span>
					</MetaListItem>
				)}
			</MetaList>
		</Meta>
	);
}
