import React from 'react';
import styled, { css } from 'styled-components';
import { faFilterList } from '@fortawesome/pro-regular-svg-icons/faFilterList';

import RefinementDropdown from 'components/algolia/RefinementDropdown';
import CustomSearchBox from 'components/algolia/SearchBox';
import ScreenReaderText from 'components/screen-reader-text';

const Filters = styled.fieldset`
	display: flex;
	flex-wrap: wrap;
	margin: 30px auto 0;
	${p =>
		p.theme.media.medium(css`
			max-width: 600px;
			margin: 45px auto 0;
		`)}
`;

const Field = styled.div`
	width: 100%;
	${p =>
		p.theme.media.smallOnly(css`
			&:not(:last-of-type) {
				margin: 0 0 20px;
			}
		`)}
	${p =>
		p.theme.media.medium(css`
			width: calc(50% - 20px);
			margin: 0 10px;
		`)}
	> div {
		margin: 0;
	}
`;

export default function SearchFilters({ searching, setSearching }) {
	return (
		<Filters>
			<ScreenReaderText OuterWrapper="legend">
				Søk eller velg kategori for å finne spesifikke blogginnlegg
			</ScreenReaderText>
			<Field>
				<CustomSearchBox
					placeholder="Søk ..."
					searching={searching}
					setSearching={setSearching}
					id="blog-search-input"
					name="blog-search-input"
					location="Blog Archive"
				/>
			</Field>
			<Field>
				<RefinementDropdown
					id="blog-categories"
					placeholder="Kategorier ..."
					label="Kategorier ..."
					icon={faFilterList}
					attribute="category"
				/>
			</Field>
		</Filters>
	);
}
